import React from 'react';

const BurgerIcon = (props) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"  onClick={props.onClick}
      style={{ cursor: 'pointer' }} {...props}>
      <path d="M3.75 12H20.25" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.75 6H20.25" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.75 18H20.25" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
}
const PlusIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path d="M2.5 8.46011L13.5 8.46011" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 13.9601L8 2.96011" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
const MinusIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
      <path d="M2.5 8.46011L13.5 8.46011" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
const CloseIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" onClick={props.onClick}
    style={{ cursor: 'pointer' }} {...props}>
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M23.9208 8.99847C24.1746 8.74463 24.1746 8.33307 23.9208 8.07923C23.6669 7.82539 23.2554 7.82539 23.0015 8.07923L16 15.0807L8.99847 8.07923C8.74463 7.82539 8.33308 7.82539 8.07924 8.07923C7.82539 8.33307 7.82539 8.74463 8.07924 8.99847L15.0808 16L8.07924 23.0015C7.82539 23.2553 7.82539 23.6669 8.07924 23.9207C8.33308 24.1746 8.74463 24.1746 8.99847 23.9207L16 16.9192L23.0015 23.9207C23.2554 24.1746 23.6669 24.1746 23.9208 23.9207C24.1746 23.6669 24.1746 23.2553 23.9208 23.0015L16.9192 16L23.9208 8.99847Z" 
        fill="white" 
      />
    </svg>
  );
}

export {
    BurgerIcon,
    PlusIcon,
    MinusIcon,
    CloseIcon
}