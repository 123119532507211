import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {Container, Row } from 'react-bootstrap';

import HeaderTop from "./HeaderTop";
import Menu from "./Menu/Menu";

import './Header.scss';

const Header = ({ fixed, homeArticleId, TransparentHeader }) => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {         
        siteConfig {
          Header_Bar_Text
          Header_Bar_Text_Mobile
          Header_Show_Book_Now
        }
      }
    }
  `);

  const siteData = data.glstrapi.siteConfig;
  const [scroll, setScroll] = useState(false);
  const [propertySearch, setPropertySearch] = useState({ buy: false, rent: false });
  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScroll(window.scrollY > 60);
    const handleEvent = () => {
      if (!renderComponent) {
        scriptInsert();
        setRenderComponent(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleEvent);
    window.addEventListener("keypress", handleEvent);
    window.addEventListener("touchmove", handleEvent);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleEvent);
      window.removeEventListener("keypress", handleEvent);
      window.removeEventListener("touchmove", handleEvent);
    };
  }, [renderComponent]);

  const scriptInsert = () => {
    if (!document.getElementById("chat")) {
      const script = document.createElement('script');
      script.id = 'chat';
      script.src = '//code.jivosite.com/widget/fOl4Wvtle2';
      document.head.appendChild(script);

      // Microsoft Clarity
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
        t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i + "?ref=bwt";
        y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "g80an0zegk");

      // Meta tags
      if (typeof window !== "undefined" && homeArticleId?.id === "609a85c0e649554c60d8d13c") {
        const metaTags = [
          { name: "yandex-verification", content: "9779a02309d58b3d" },
          { name: "google-site-verification", content: "la8qX1x4Od4EgmC3R4SU_DttOTBStjqCflJh9jIhCF4" }
        ];
        metaTags.forEach(metaData => {
          const meta = document.createElement('meta');
          meta.name = metaData.name;
          meta.content = metaData.content;
          document.head.appendChild(meta);
        });
      }

      if (typeof window !== "undefined") {
        const yandexScript = document.createElement('script');
        yandexScript.type = 'text/javascript';
        yandexScript.innerHTML = '(function(m,e,t,r,i,k,a){m[i]=m[i]|| function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date(); for (var j = 0 ; j < document.scripts.length; j++){if (document.scripts[j].src === r) { return; }} k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(91132752, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true });';
        document.head.appendChild(yandexScript);

        const noscript = document.createElement('noscript');
        noscript.innerHTML = '<div><img src="https://mc.yandex.ru/watch/91132752" style="position:absolute; left:-9999px;" alt="" /></div>';
        document.head.appendChild(noscript);
      }
    }
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.toggle('select-fixed', fixed && scroll);
      document.body.classList.add('default-class');
    }
  }, [fixed, scroll]);

  return (
    <header className={`header ${TransparentHeader ? "header-transparent" : ""} ${fixed && scroll ? "header--fixed" : ""}`} onMouseLeave={() => setPropertySearch({ buy: false, rent: false })}>
      <Container fluid>
          <HeaderTop siteData={siteData} />
          <Menu />   
      </Container>
    </header>
  );
};

export default Header;