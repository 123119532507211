import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import HTMLReactParser from 'html-react-parser';
import { Link } from "@StarberryUtils";
import { LogoBlue } from "../icon";

import './FooterBottom.scss';

const socialLinks = [
    { name: 'Facebook', iconClass: 'icon-fb', key: 'Facebook_Link' },
    { name: 'Twitter', iconClass: 'icon-twitter', key: 'Twitter_Link' },
    { name: 'TikTok', iconClass: 'icon-tiktok', key: 'Tiktok_Link' },
    { name: 'Instagram', iconClass: 'icon-insta', key: 'Instagram_Link' },
    { name: 'YouTube', iconClass: 'icon-youtube', key: 'Youtube_Link' },
    { name: 'LinkedIn', iconClass: 'icon-linkedin', key: 'Linkedin_Link' }
];
const cleanHtml = (html) => html.replace(/<br\s*\/?>/gi, '');

const FooterBottom = ({ footerInfo }) => {
    const year = new Date().getFullYear();
    const currentPage = typeof window !== 'undefined' ? window.location.pathname : '';

    const contactNumber = currentPage === '/holiday-homes/'
        ? '+971 52 850 0156'
        : footerInfo.Phone;
    return (
        <div className="footer-bottom-wrap">
            <Row>
                <Col md={6} className="footer-left">
                    <Link to="/">
                        <LogoBlue className="footer-logo" aria-label="Dacha Real Estate Logo" />
                    </Link>
                    <div className="footer-text">
                        <span className="text-xxs">Copyright © {year} Dacha Real Estate </span>
                        <span className="text-xxs"> Site by{' '}
                            <a href="https://starberry.tv/" rel="noopener noreferrer" target="_blank" className="starberry">
                                Starberry
                            </a>.
                        </span>
                    </div>
                </Col>
                <Col md={6} className="footer-right">
                    <div className="footer-call-us">
                        <i className="icon-phone"></i> <Link to={`tel:${contactNumber}`} className="text-lg-bold">{contactNumber}</Link>
                    </div>
                    <div className="footer-content">
                        {footerInfo.Available_Timings &&
                            <div className="available-timing text-xxs">
                                {HTMLReactParser(footerInfo.Available_Timings)}
                            </div>
                        }
                    </div>
                    <ul className="social-links">
                        {socialLinks.map(({ name, iconClass, key }) =>
                            footerInfo[key] && (
                                <li key={name}>
                                    <a href={footerInfo[key]} rel="noopener noreferrer" target="_blank">
                                        <i className={iconClass} alt={`${name} logo`}></i>
                                    </a>
                                </li>
                            )
                        )}
                    </ul>
                </Col>
            </Row>
        </div>
    );
};

export default FooterBottom;