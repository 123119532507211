import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Nav, Navbar } from "react-bootstrap";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import useDeviceMedia from "../../utils/useDeviceMedia";
import useHasScrolled from "../../utils/useHasScrolled";
import MenuList from "./MenuList";
import { useLocation } from "@reach/router";
import LogoWhite from "../../../images/logo.svg";
import LogoBlue from "../../../images/logo-blue.svg";
import whatsapp from "../../../images/footer/whatsapp.png";
import Loadable from "@loadable/component";

import './Menu.scss';

const GoogleTranslate = Loadable(() => import("../../GoogleTranslateWidget/GoogleTranslateWidget"));

const Menu = (props) => {
  // Scroll detection
  const scrolled = useHasScrolled();
  // Device type detection
  const { isCustomMax } = useDeviceMedia({ max: "991.98px" });
  const [renderComponent, setRenderComponent] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  // Fetch menus using GraphQL
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        headerMenus {
          id
          Parent_Label
          Parent_Link
          Parent_Menu_Link {
            id
            URL
          }
          Child_Menu {
            Child_Label
            Child_Link
            Child_Menu_Link {
              id
              URL
            }
            Child_Sub_Menu {
              Child_Sub_Menu_Label
              Child_Sub_Menu_Link
              Child_Sub_Menu_Menu_Link {
                id
                URL
              }
            }
          }
        }
        menuBurgers {
          id
          Parent_Label
          Parent_Link {
            id
            URL
          }
          Sort
          Sub_Menus {
            Label
            Link {
              id
              URL
            }
          }
        }
      }
    }
  `);

  const menus = data.glstrapi.headerMenus;
  const burgerMenus = data.glstrapi.menuBurgers;
  const [scroll, setScroll] = useState(false);

  const locationLink = `${process.env.GATSBY_SITE_URL}${location.pathname}`;
  let whatsappLink = `https://wa.me/97144232006?text=Hello!%20I%20want%20to%20know%20more%20about%20${locationLink}`;

  if (location.search.includes("utm_source")) {
    whatsappLink = `https://wa.me/97144232006?text=*G*%20Hello!%20I%20want%20to%20know%20more%20about%20${locationLink}`;
  }

  useEffect(() => {
    const handleScroll = () => setScroll(window.scrollY > 60);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleComponentRender);
    window.addEventListener("keypress", handleComponentRender);
    window.addEventListener("touchmove", handleComponentRender);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleComponentRender);
      window.removeEventListener("keypress", handleComponentRender);
      window.removeEventListener("touchmove", handleComponentRender);
    };
  }, [renderComponent]);

  const handleComponentRender = () => {
    if (!renderComponent) {
      setRenderComponent(true);
    }
  };
  const logoSrc = scrolled ? LogoBlue : (isHomePage ? LogoWhite : LogoBlue);
  return (
    <div className="header-menu-wrap">
      <Container>
      <Navbar expand="lg" className="header-navbar">
        <div className="header-menu-left">
          <Link to="/" className="navbar-brand">
            <img loading="lazy" className="logo" src={logoSrc} alt="Dacha logo" />
          </Link>
        </div>
        <Navbar.Collapse id="header-navbar-nav">
          <Nav className="m-auto desktop-menu" id="desktop-nav">
            {menus && menus.map((item, index) => (
              <MenuList key={index} item={item} />
            ))}
          </Nav>
        </Navbar.Collapse>
        <div className="header-menu-right">

        {/* Google Translate Widget Start */}
        <div className="ml-auto d-md-flex lang-btn google-translate-widget d-block d-md-block d-lg-block">
          {renderComponent && <GoogleTranslate />}
        </div>
        {/* Google Translate Widget End */}

        <div className="d-flex d-lg-none whatsapp-tablet">
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="whatsapp">
            <img loading="lazy" src={whatsapp} alt="whatsapp" />
          </a>
        </div>

        <div className="nav-right ml-auto d-none d-lg-flex widget-google-translate">
          <Link to="/contact" className="btn btn-primary">
              List Your Property
          </Link>
        </div>
        
        {isCustomMax && (
          <div className="burger-icon">
              <BurgerMenu
                menus={burgerMenus}
                isTransparent={scrolled}
              />
          </div> 
        )}
        </div>
        
    </Navbar>
    </Container>
    </div>
  );
};

export default Menu;