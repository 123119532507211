import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router"; // Ensure these imports are correct
import { Link } from "@StarberryUtils";
import { GetURL } from "../../common/site/functions";


const MenuList = ({ item }) => {
  const [active, setActive] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    const isActive = 
      (item?.Parent_Link?.URL && pathname.includes(item?.Parent_Link?.URL)) ||
      (item?.Parent_Menu_Link?.URL && pathname.includes(item?.Parent_Menu_Link?.URL));
    
    setActive(isActive);
  }, [item?.Parent_Link?.URL, item?.Parent_Menu_Link?.URL, pathname]);

  // Check if any child menu has sub-menus
  const hasSubSubMenus = item?.Child_Menu?.some(childMenu => childMenu?.Child_Sub_Menu?.length > 0);

  return (
    <div className="nav-item">
      <Link
          className={`nav-link text-xs ${item?.Child_Menu?.length > 0 ? "arrow" : ""} ${active ? "menu-active" : ""}`}
          to={item.Parent_Link 
            ? item.Parent_Link 
            : item.Parent_Menu_Link 
              ? `/${GetURL(item.Parent_Menu_Link.id)}` 
              : ''
          }
          title={item?.Parent_Label}
        >
          {item?.Parent_Label}
      </Link>
      {item?.Child_Menu?.length > 0 && (
        <div className={`sub-menu-wrap ${hasSubSubMenus ? 'sub-sub-menu-wrap' : ''}`}>
          {item?.Child_Menu?.map((childMenu) => (
            <div className="sub-menu-item" key={childMenu?.Child_Label}>
              <div className={`nav-parent ${hasSubSubMenus ? 'nav-live-parent' : ''}`}>
                <Link
                  className={`nav-sub-head-link ${hasSubSubMenus ? 'text-xs-bold' : 'text-xs'}`}
                  to={childMenu.Child_Link 
                    ? childMenu.Child_Link 
                    : childMenu.Child_Menu_Link 
                      ? `/${GetURL(childMenu.Child_Menu_Link.id)}` 
                      : ''
                  }
                  title={childMenu?.Child_Label}
                >
                  {childMenu?.Child_Label}
                </Link>
              </div>
              {childMenu?.Child_Sub_Menu?.map((subMenu) => (
                <div className="sub-link-holder" key={subMenu?.Child_Sub_Menu_Label}>
                  <Link
                    className="nav-sub-sub-link text-xs"
                    to={subMenu.Child_Sub_Menu_Link 
                      ? subMenu.Child_Sub_Menu_Link 
                      : subMenu.Child_Sub_Menu_Menu_Link 
                        ? `/${GetURL(subMenu.Child_Sub_Menu_Menu_Link.id)}` 
                        : ''
                    }
                    title={subMenu?.Child_Sub_Menu_Label}
                  >
                    {subMenu?.Child_Sub_Menu_Label}
                  </Link>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuList;