import React, { useState } from "react";
import loadable from "@loadable/component";
import "./FooterPopularSearch.scss";

const PopularSearch = loadable(() => import("../PopularSearch/PopularSearch"));

const FooterPopularSearch = ({ popularSearch }) => {
    const [showSearch, setShowSearch] = useState(true);

    const displaySearch = () => {
        setShowSearch(!showSearch);
    };

    return (
        <div className="footer-popular-search-wrap sec-pdy-80">
            <div className="popular-search-list">
                <h6 className={`text-md-bold ${showSearch ? "pop-closed" : "pop-opened"}`} onClick={displaySearch}>
                   <span>Popular Searches</span> <i className={showSearch ? "icon-plus" : "icon-minus"}></i>
                </h6>
                <div className={showSearch ? "d-none" : ""}>
                    <PopularSearch popular_search={popularSearch} />
                </div>
            </div>
        </div>
    );
};

export default FooterPopularSearch;