import React, { useState } from "react";
import ModalTiny from "react-bootstrap/Modal";
import { Link } from "@StarberryUtils";
import { GetURL } from "../../common/site/functions";
import { BurgerIcon, PlusIcon, MinusIcon, CloseIcon } from "../HeaderIcon"; 
import BookForm from "../../forms/book-a-viewing-form";
import LogoWhite from "../../../images/logo.svg";
import "./BurgerMenu.scss";

const BurgerMenu = ({ menus, isTransparent }) => {
  const [showModal, setShowModal] = useState(false);
  const [openSection, setOpenSection] = useState(null);
  const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false);

  const handleSectionClick = (sectionId) => {
    setOpenSection(openSection === sectionId ? null : sectionId);
  };

  const openBookViewformModal = () => {
    setBookViewingformOpen(true);
  };

  const closeBookViewformModal = () => {
    setBookViewingformOpen(false);
  };

  return (
    <>
      <BurgerIcon className="menu-bar-icon" white={isTransparent} onClick={() => setShowModal(true)} />
      {showModal && (
        <div className="burger-menu-overlay">
          <div className="burger-menu-wrap">
            <div className="burger-header">
              <Link href="/">
                <img src={LogoWhite} alt="logo" className="brand-logo" />
              </Link>
              <CloseIcon className="close-btn" onClick={() => setShowModal(false)} />
            </div> 
            <div className="burger-menu-section">
              {menus?.map((item) => (
                <div key={item.id} className="menu-item">
                  <div className="menu-title text-sm" onClick={() => handleSectionClick(item.id)}>
                    <Link to={ item?.Parent_Link ? `/${GetURL(item.Parent_Link.id)}` : '#'} className="parent-menu-item text-sm"> {item.Parent_Label}</Link>                    
                    {item.Sub_Menus.length > 0 && (
                      openSection === item.id ? <MinusIcon className="toggle-icon" /> : <PlusIcon className="toggle-icon" />
                    )}
                  </div>
                  {openSection === item.id && (
                    <ul className="submenu-list">
                      {item.Sub_Menus.map((subMenu) => (
                        <li><Link key={subMenu.Link.id} to={ subMenu.Link ? `/${GetURL(subMenu.Link.id)}` : '#'} className="submenu-item text-sm">                          
                          {subMenu.Label}
                        </Link></li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div> 
            <div className="helper-block">
              <h6 className="text-sm">Need help?</h6>
              <p className="text-sm">Book an appointment with one of our property experts.</p>
              <button className="btn btn-primary" onClick={openBookViewformModal}>Book an Appointment</button>
            </div>          
          </div>
        </div>
      )}
      <ModalTiny size="md" show={modalBookViewformOpen} onHide={closeBookViewformModal} className="book-a-viewing-modal share-to-friend-modal">
        <ModalTiny.Header className="contact-close-btn">
          <span className="close-menu" onClick={closeBookViewformModal}><i className="close-icon"></i></span>
          <ModalTiny.Title className="w-100"><h2 className="text-center mb-0">Book an Appointment</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section">
          <BookForm />
        </ModalTiny.Body>
      </ModalTiny>
    </>
  );
};

export default BurgerMenu;