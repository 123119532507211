import { useMediaQuery } from "./useMediaQuery"

// return truthy value for media queries
const useDeviceMedia = (props) => {
  // default min max
  const { min, max } = props ? props : { min: "0px", max: "0px" }

  const isMobile = useMediaQuery("(max-width: 767.98px)")
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1023px)")
  const isTabletLarge = useMediaQuery("(min-width: 1024px) and (max-width: 1279px)")
  const isLargeScreen = useMediaQuery("(min-width: 1280px) and (max-width: 1535px)")
  const isLargeScreenOnly = useMediaQuery("(min-width: 1280px) and (max-width: 1535px)")
  const isXLargeScreen = useMediaQuery("(min-width: 1536px)")

  const isCustomMin = useMediaQuery(`(min-width: ${min})`)
  const isCustomMax = useMediaQuery(`(max-width: ${max})`)

  return {
    isMobile,
    isTablet,
    isTabletLarge,
    isLargeScreen,
    isLargeScreenOnly,
    isXLargeScreen,
    isCustomMin,
    isCustomMax,
  }
}

export default useDeviceMedia