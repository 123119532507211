import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router"
import axios from "axios";
import FooterTop from "./FooterTop/FooterTop";
import FooterBottom from "./FooterBottom/FooterBottom";
import FooterPopularSearch from "./PopularSearch/FooterPopularSearch";

import whatsapp from "../../images/footer/whatsapp.png"
import './Footer.scss';

// markup
const Footer = (props) => {
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
        menuFooter {
            Add_Menu {
              Label
              Link {
                URL
              }
              Sub_Menus {
                Label
                Link {
                  URL
                  id
                }
              }
            }
          }
        siteConfig {
            Facebook_Link
            Instagram_Link
            Linkedin_Link
            Youtube_Link
            Twitter_Link
            Tiktok_Link
            Phone
            Available_Timings
            Footer_Text_Widget {
                Widget_Title
                Widget_Content
                Button1_Label
                Button1_Link{
                  id
                  URL
                }
                Button2_Label
                Button2_Link{
                  id
                  URL
                }
            }
        }
    }
  }
`);


  const menus = data.glstrapi.menuFooter;
  const site = data.glstrapi.siteConfig;
  const logoBlue = `${process.env.GATSBY_SITE_URL}/images/logo-blue.svg`;
  const footerWidget = site?.Footer_Text_Widget;

  let [reviewCount, setReviewCount] = React.useState(0);
  let [averageRating, setAverageRating] = React.useState(0);
  const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
  const strapiconfig = {
    headers: {
      Authorization:
        `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }
  const urlReviws = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`;
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)// could be from env files 
      setReviewCount(data.length);
      let sumOfScores = 0;
      data.map((review) => {
        const reviewStringValue = review.starRating;
        sumOfScores += wordToNumber[reviewStringValue];
      })
      setAverageRating(Math.round(sumOfScores / data.length * 10) / 10);
    } catch (error) {
      // cache it if fail/error;

    }
  }
  useEffect(() => {
    getitems(urlReviws);
  }, []);
 
  const location = useLocation();

  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })

  }, [])

  var location_link = process.env.GATSBY_SITE_URL + location.pathname;

  var whatsapp_link = `https://wa.me/97144232006?text=Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
  if (location.search.indexOf("utm_source") > 0) {
    whatsapp_link = `https://wa.me/97144232006?text=*G*%20Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
  }
  const isHomePage = location.pathname === '/';
  return (
    <React.Fragment>
      {isHomePage && averageRating !== 0 && reviewCount !== 0 && (
        <Helmet>
          <script type="application/ld+json">
            {`
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "@id": "https://www.dacha.ae/#Organization",
                "url": "https://www.dacha.ae/",
                "name": "Real Estate Agents in Dubai | Dubai Real Estate Brokers | Dacha Real Estate",
                "description": "Want to buy, rent, or sell your property in Dubai? Dacha is listed among the top real estate companies in Dubai. Connect with the best real estate agents in Dubai today.",
                "logo": "${logoBlue}", 
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "${averageRating}",
                    "reviewCount": "${reviewCount}"
                },
                "sameAs": [
                    "https://www.facebook.com/dacharealestate/",
                    "https://www.linkedin.com/company/dacharealestate/",
                    "https://twitter.com/Dachateam",
                    "https://www.instagram.com/dacharealestate/",
                    "https://www.tiktok.com/@dacharealestate?lang=en",
                    "https://www.youtube.com/c/DachaRealEstateDubai/"
                ],
                "email": "info@dacha.ae",
                "location": [
                    {
                        "@type": "Place",
                        "name": "Dacha - Real Estate Agents in Dubai Marina",
                        "telephone": "+971 4 423 2006",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Office 2101 & 2404, Marina Plaza, Dubai Marina",
                            "addressLocality": "Dubai",
                            "addressRegion": "UAE",
                            "postalCode": "171418",
                            "addressCountry": "UAE"
                        }
                    },
                    {
                        "@type": "Place",
                        "name": "Dacha - Real Estate Agents in Palm Jumeirah",
                        "telephone": "+971 4 423 2006",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "7 Palm Jumeirah Road, The Palm Jumeirah",
                            "addressLocality": "Dubai",
                            "addressRegion": "UAE",
                            "addressCountry": "UAE"
                        }
                    }
                ],
                "parentOrganization": {
                    "@type": "Organization",
                    "@id": "https://www.dacha.ae/#Organization",
                    "url": "https://www.dacha.ae/",
                    "name": "Real Estate Agents in Dubai | Dubai Real Estate Brokers | Dacha Real Estate",
                    "alternateName": "Dacha Real Estate"
                }
            }
            `}
          </script>
        </Helmet>
      )}

      <footer className="footer-wrapper sec-pdy-120">
        <Container>
          <FooterTop menus={menus} footerWidget={footerWidget}  />
          {props.popularSearch && (
            <FooterPopularSearch popularSearch={props.popularSearch} />
          )}
          <FooterBottom footerInfo={site} />
        </Container>
        {renderComponent && (
          <div className="d-none d-lg-block">
            <a href={whatsapp_link} target="_blank" className="whatsapp"><img loading="lazy" src={whatsapp} alt="whatsapp" /></a>
          </div>
        )}
      </footer>
    </React.Fragment >
  )
}

export default Footer